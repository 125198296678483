import React, {useRef, useEffect} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

// Components
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import PageTitle from './components/Helpers/PageTitle';
import Content404 from './components/404/Content404';

// Pages
import Homepage from './pages/Homepage';
import Faq from './pages/Faq';
import Tickets from './pages/Tickets';
import Work from './pages/Work';

// Import global styles
import { GlobalStyle } from './theme/GlobalStyle';
import { Theme } from './theme/Themes';

import { ReactComponent as Accessibility } from './assets/accessibility.svg';
import { ThemeProvider as TP, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const ref1 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    var scroll = function() {
      if(ref1.current) {
        ref1.current.style.top = window.innerHeight - 100 + window.scrollY + "px"
      }
    }
    window.onscroll = scroll
    scroll()
  }, [])

  return <>

    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      
        <div className='accessibility' ref={ref1}>
          <a href="https://mayball.org/file/2025-sjcmb-access.pdf" rel="noreferrer" target="_blank"><Accessibility width={70}/> </a>
        </div>
      <Router>
        <PageTitle title={''} />
        
        {/* Return to top of page on move page */}
        <Route path="/" render={() => { window.scroll({top: 0, left: 0}); return null; }} />

        {/* <Navbar /> */}

        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>

          <Route exact path="/faq">
            <Faq />
          </Route>

<Route exact path="/tickets">
  <Tickets />
</Route>

<Route exact path="/work">
<TP theme={darkTheme}>
      <CssBaseline />
  <Work />

  </TP>
</Route>

          <Redirect to="/" />
        </Switch>
      </Router>
    </ThemeProvider>
  </>;
}

export default App;