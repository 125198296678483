
// Styles
import * as styles from './styles/HeaderStyles';

import Eagle from './../../assets/eagle.svg';

export default function NavBar () {
    return <styles.Navbar>
      <styles.NavbarLeft to={'/'}>
        <img src={Eagle} /><p>St John's College<br />May Ball 2025</p>
      </styles.NavbarLeft>
      <styles.NavbarRight>
        <styles.NavbarLink to="/tickets">Tickets</styles.NavbarLink>
        {/*<styles.NavbarLink to="/work">Work for us!</styles.NavbarLink>*/}
        <styles.NavbarLink to="/faq">FAQ</styles.NavbarLink>
      </styles.NavbarRight>
    </styles.Navbar>
}