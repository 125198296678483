import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

// Components
import FadeIn from './../Helpers/FadeIn';

// Styles
import * as HomepageStyles from './styles/HomepageStyles';
import * as styles from './styles/VideoStyles';

// Images
import VideoThumb from './../../assets/video.jpg';

const Video = () => {

  const [player,setPlayer] = useState<any>(null);
  const [showThumb, setShowThumb] = useState(true);
  const [fadeInAnimate, setFadeInAnimate] = useState(false);

  const videoOpts = {
    width: "100%",
    playerVars: {
      modestbranding: 1 as const,
      rel: 0 as const,
      controls: 0 as const,
      disablekb: 0 as const
    }
  }

  const handleOnReady = (e: Event) => {
    setPlayer(e.target);
  }
  
  const playVideo = () => {
    setShowThumb(false);
    if (player) player.playVideo();
  }

  return <>
    <styles.Section fullHeightMobile>
      <HomepageStyles.MainWrapper width="60%" fullHeightMobile>
        <HomepageStyles.WideColumn>
          <VisibilitySensor onChange={isVisible => { if (isVisible) setFadeInAnimate(true) }}>
            <styles.YTAspectWrapper>
              {showThumb ? <styles.YTThumb>
                <styles.ThumbnailText>
                  <FadeIn triggered={fadeInAnimate}>
                    <styles.EmphasisBlock>2024 <span>BALL</span></styles.EmphasisBlock>
                    <styles.ThumbnailTitle>Discover the ball</styles.ThumbnailTitle>
                    <styles.ThumbnailBody>Watch the highlights from the 2024 May Ball and find more videos on our YouTube channel.</styles.ThumbnailBody>
                  </FadeIn>
                </styles.ThumbnailText>
                <img src={VideoThumb} onClick={playVideo} />
              </styles.YTThumb> : null}
              <styles.YT videoId="QmSspwhQ43g" opts={videoOpts} onReady={handleOnReady} />
            </styles.YTAspectWrapper>
          </VisibilitySensor>
          <styles.VideoInfoMobile>
            <FadeIn triggered={fadeInAnimate}>
              <styles.EmphasisBlock>2024 <span>BALL</span></styles.EmphasisBlock>
              <styles.ThumbnailTitle>Discover the ball</styles.ThumbnailTitle>
              <styles.ThumbnailBody>Watch the highlights from the 2024 May Ball and find more videos on our YouTube channel.</styles.ThumbnailBody>
            </FadeIn>
          </styles.VideoInfoMobile>
        </HomepageStyles.WideColumn>
      </HomepageStyles.MainWrapper>
    </styles.Section>
  </>
}

export default Video;