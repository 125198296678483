import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/fontawesome-free-solid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import VisibilitySensor from 'react-visibility-sensor';

// Components
import FadeIn from './../Helpers/FadeIn';

// Styles
import * as HomepageStyles from './styles/HomepageStyles';
import * as styles from './styles/HeaderStyles';

// Images
import Eagle from './../../assets/eagle.svg';
import NavBar from '../Helpers/NavBar';

interface FloatingInfoProps {
  message: string,
  url: string,
  icon: IconProp
}

const FloatingInfo: React.FC<FloatingInfoProps> = ({
  message, url, icon, ...props
}) => {

  const [fadeInAnimate, setFadeInAnimate] = useState(false);

  return <>
    <VisibilitySensor onChange={isVisible => { if (isVisible) setFadeInAnimate(true) }}>
      <styles.FloatingInfo>
      <FadeIn triggered={fadeInAnimate}>
          <a href={url}>
            <styles.IconWrapper>
              <FontAwesomeIcon icon={icon} />
            </styles.IconWrapper>
            <styles.Message>
                <p>{message}</p>
            </styles.Message>
          </a>
        </FadeIn>
      </styles.FloatingInfo>
    </VisibilitySensor>
  </>
}

const Header = () => {
  return <>
    <styles.Section fullHeightMobile>
      <styles.BackgroundImage backgroundImage="/assets/img/hero.jpg" />
      <styles.BackgroundGradient></styles.BackgroundGradient>
      <NavBar />
      <styles.TextWrapper>
        <styles.Subtitle>Tuesday June 24th 2025</styles.Subtitle>
        <styles.Title><span>S</span>t  <span>J</span>ohn's  <span>C</span>ollege<br /><span>M</span>ay  <span>B</span>all</styles.Title>
      </styles.TextWrapper>
      {/* <styles.Floating>
      <FloatingInfo 
        message="Committee statement from 31st March 2021"
        url="/file/notice_end_lent.pdf"
        icon={faExclamationCircle as IconProp}
      />
      <FloatingInfo 
        message="Committee statement from 21st January 2021"
        url="/file/notice_early_lent.pdf"
        icon={faExclamationCircle as IconProp}
      />
      </styles.Floating> */}
    </styles.Section>
  </>
}

export default Header;
