import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

// Contexts

// Styles
import * as styles from './FooterStyles';

// Images
import Eagle from './../../assets/eagle.svg';

const Footer = () => {
  return <>
    <styles.MainWrapper>
      <styles.ContentWrapper>
        <styles.LogoMobile>
          <styles.LogoMobileImage src={Eagle} />
        </styles.LogoMobile>
        <styles.WideSection>
          <styles.ContentSection width={'10%'}>
            <styles.Logo src={Eagle} />
          </styles.ContentSection>
          <styles.ContentSection width={'60%'} padding={true}>
            <styles.SectionTitle>COPYRIGHT</styles.SectionTitle>

            <styles.Type>&copy; Copyright {new Date().getFullYear()} St John's College May Ball. </styles.Type>
            
            <styles.SectionTitle><a href="https://mayball.org/file/2024-sjcmb-terms.pdf">Terms and Conditions apply</a></styles.SectionTitle>

            <styles.Type style={{fontSize: "80%"}}>Company number 14684560. VAT number GB214341995.</styles.Type>
          </styles.ContentSection>
          
            <styles.FooterDivider onlyMobile />

          <styles.ContentSection width={'30%'} right={true}>
            <styles.SectionTitle>FOLLOW THE BALL</styles.SectionTitle>

            <styles.SocialLink href={ 'https://www.facebook.com/StJohnsCollegeMayBall' }><FontAwesomeIcon icon={faFacebook} /></styles.SocialLink>
            <styles.SocialLink href={ 'https://www.instagram.com/stjohnsmayball/' }><FontAwesomeIcon icon={faInstagram} /></styles.SocialLink>
            <styles.SocialLink href={ 'https://twitter.com/stjohnsmayball' }><FontAwesomeIcon icon={faTwitter} /></styles.SocialLink>
          
          </styles.ContentSection>
        </styles.WideSection>
      </styles.ContentWrapper>

    </styles.MainWrapper>
  </>
}

export default Footer;